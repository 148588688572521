




































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private tongue: any;
  private youwu: any = ["有", "无"];
  private seze: any = ["正常", "紫红", "淡紫", "紫黑"];
  private changdu: any = ["正常", "延长", "短"];
  private kuandu: any = ["正常", "粗胀", "细"];
  private get t() {
    return this.tongue;
  }
  private set t(val) {
    this.$emit("update:tongue", val);
  }
  private get root() {
    return this.$refs.root as HTMLElement;
  }
}
