


























































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private tongue: any;
  @Prop()
  private cBiaozhu: any = "";
  private youwu: any = ["有", "无"];
  private chengdu: any = ["轻", "中", "重"];
  private shese: any = ["淡", "淡红", "红", "暗红", "绛", "淡紫", "暗紫"];
  private weizhi: any = ["舌尖", "舌左边", "舌右边", "舌中", "舌根"];
  private pangshou: any = ["胖", "瘦", "胖瘦适中"];
  private laonen: any = ["娇嫩", "苍老", "适中"];
  private shetai: any = [
    "萎软",
    "强硬",
    "歪斜",
    "颤动",
    "吐弄",
    "短缩",
    "正常",
  ];
  private fangxiang: any = ["偏左", "偏右"];
  private sheshen: any = ["枯舌", "荣舌"];
  private ifShowSeduDetail: boolean = false;
  private cPart: any = "LAB";
  private get t() {
    return this.tongue;
  }
  private set t(val) {
    this.$emit("update:tongue", val);
  }
  private get c() {
    return this.cBiaozhu;
  }
  private set c(val) {
    this.$emit("update:cBiaozhu", val);
  }
  private get root() {
    return this.$refs.root as HTMLElement;
  }
  private getColor(type: any) {
    switch (type) {
      case "齿痕":
        return "red";
      case "裂纹":
        return "#67D5B5";
      case "点刺":
        return "yellow";
      case "瘀点":
        return "green";
      case "瘀斑":
        return "blue";
      case "胖瘦":
        return "black";
      case "舌态":
        return "#EA0AF6";
      case "苔质面积占舌比":
        return "#0AF60A";
      case "剥落":
        return "orange";
    }
  }
  /**
   * @description 打开色度值详情
   */
  private openSeduDetail() {
    this.ifShowSeduDetail = true;
  }
  /**
   * @description 改变部分
   */
  private changeSeduPart(part: any) {
    this.cPart = part;
  }
  /**
   * @description 点击开始标注
   */
  private startBiaozhu(part: any) {
    // this.c = part;
    if (part === "齿痕") {
      this.$emit("biaozhuChihen");
    } else if (part === "裂纹") {
      this.$emit("biaozhuLiewen");
    } else if (part === "瘀斑") {
      this.$emit("biaozhuYuban");
    } else if (part === "胖瘦") {
      this.$emit("biaozhuPangshou");
    } else if (part === "点刺") {
      this.$emit("biaozhuDianci");
    } else if (part === "瘀点") {
      this.$emit("biaozhuYudian");
    } else if (part === "舌态") {
      this.$emit("biaozhuShetai");
    }
  }
  /**
   * @description 删除某个瘀点
   */
  private deleteYudian(index: any) {
    this.$emit("deleteYudian", index);
  }
  /**
   * @description 删除某个舌态
   */
  private deleteShetai(index: any) {
    this.$emit("deleteShetai", index);
  }
  /**
   * @description 删除某个点刺
   */
  private deleteDianci(index: any) {
    this.$emit("deleteDianci", index);
  }
  /**
   * @description 删除某个齿痕
   */
  private deleteChihen(index: any) {
    this.$emit("deleteChihen", index);
  }
  /**
   * @description 删除某个裂纹
   */
  private deleteLiewen(index: any) {
    this.$emit("deleteLiewen", index);
  }
  /**
   * @description 删除某个瘀斑
   */
  private deleteYuban(index: any) {
    this.$emit("deleteYuban", index);
  }
  /**
   * @description 删除胖瘦
   */
  private deletePangshou(part: any) {
    this.$emit("deletePangshou", part);
  }
}
