


























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import html2canvas from "html2canvas";
import { dataUrlToBlob } from "@/utils/base64ToBlob";
import { CalCuttedTongueArea } from "@/request/research";
import { UploadImage } from "@/request/common";
@Component({})
export default class IdrPicCanvas extends Vue {
  @Prop()
  private picSrc: any; // 分割前图片
  @Prop()
  private tongue: any; // 舌图信息
  @Prop()
  private picType: any; // origin、
  private cuttedArea: any = 0; // 分割后舌图的像素面积
  private canvasWrap: any = {};
  private hammer: any = {};
  private sourceImage: any = {
    imgObj: {},
    x: 0,
    y: 0,
    sourceX: 0, // 底图左上角X
    sourceY: 0, // 底图左上角y
    centerX: 0, // 底图中心点X
    centerY: 0, // 底图中心点X
    width: 0, // 底图宽
    height: 0, // 底图高
    originWidth: 0,
    originHeight: 0,
    rotateAngel: 0, // 旋转角
    moveX: 0,
    moveY: 0,
    scaleX: 1,
    scaleY: 1,
    aspectRadio: 1,
  };
  private canvas: any = {};
  private context: any = {};
  private model: any = "";
  private canvasCenterX: any = 0;
  private canvasCenterY: any = 0;
  // 操作值
  private startPoint: any = {};
  private endPoint: any = {};
  // 临时路径
  private tempPath: any[] = [];
  private sheseColor: string = "#E71D36";
  private taiseColor: string = "#FEC9C9";
  private hongdianColor: string = "#FF7473";
  private yubanColor: string = "blue";
  private chihenColor: string = "red";
  private liewenColor: string = "#67D5B5";
  private kuankuanbiColor: string = "#000";
  private taizhiColor: string = "#0AF60A";
  private boluoColor: string = "orange";
  private dianciColor: string = "yellow";
  private yudianColor: string = "green";
  private shetaiColor: string = "#EA0AF6";
  private kuankuanbiModel: string = "";
  private ifMouseLeftDown: boolean = false;
  private ifMouseRightDown: boolean = false;
  // private get ossClient() {
  //   return this.$store.state.ossClient;
  // }
  private get t() {
    return this.tongue;
  }
  private set t(val) {
    this.$emit("update:tongue", val);
  }
  private get canvasClientX() {
    return (this.$refs.canvasWrap as any).getBoundingClientRect().left;
  }
  private get canvasClientY() {
    return (this.$refs.canvasWrap as any).getBoundingClientRect().top;
  }
  private getTransform() {
    return (
      "rotate(" +
      this.sourceImage.rotateAngel +
      "deg)" +
      " scale(" +
      this.sourceImage.scaleX +
      "," +
      this.sourceImage.scaleY +
      ")"
    );
  }
  @Watch("picSrc")
  private picSrcChange(src: any) {
    this.initCanvas();
  }
  /**
   * @description 获取分割后舌图的像素面积
   */
  private calCuttedTongueArea() {
    const params: any = {
      分割后舌图: this.tongue["舌象照片"]["舌面分割前图片"],
    };
    CalCuttedTongueArea(this, params).then((data: any) => {
      this.cuttedArea = data["area"];
    });
  }
  /**
   * @description 初始化canvas和显示图片
   */
  private initCanvas() {
    this.canvasWrap = this.$refs.canvasWrap;
    this.canvas = this.$refs.canvas;
    this.canvas.width = this.canvasWrap.offsetWidth;
    this.canvas.height = this.canvasWrap.offsetHeight;
    this.context = this.canvas.getContext("2d");
    // 绘制黑色背景
    this.context.fillStyle = "black";
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawImgInInitCanvas();
  }
  /**
   * @description 初始化绘制图片到canvas里
   */
  private drawImgInInitCanvas() {
    const imageObj: any = new Image();
    // 以下是在header中的带上Origin属性，没有在使用canvas的toBlob()、toDataURL()和getImageData()方法时就会出现跨域的问题
    imageObj.crossOrigin = "anonymous";
    imageObj.onload = (e: any) => {
      this.sourceImage.imgObj = imageObj;
      let dx: number = 0;
      let dy: number = 0;
      let dWidth: any = 0;
      let dHeight: any = 0;
      // 处理缩放
      this.sourceImage.originWidth = imageObj.width;
      this.sourceImage.originHeight = imageObj.height;
      const originAspectRatio: any = imageObj.width / imageObj.height;
      this.sourceImage.aspectRadio = originAspectRatio;
      if (imageObj.width >= this.canvas.width) {
        dWidth = this.canvas.width;
        dHeight = Math.round(dWidth / originAspectRatio);
        if (dHeight >= this.canvas.height) {
          dHeight = this.canvas.height;
          dWidth = Math.round(dHeight * originAspectRatio);
        }
      } else {
        dHeight = this.canvas.height;
        dWidth = Math.round(dHeight * originAspectRatio);
        if (dWidth >= this.canvas.width) {
          dWidth = this.canvas.width;
          dHeight = Math.round(dWidth / originAspectRatio);
        }
      }
      // 记录底图宽高
      this.sourceImage.width = dWidth;
      this.sourceImage.height = dHeight;
      // 处理居中
      dx = (this.canvas.width - dWidth) / 2;
      dy = (this.canvas.height - dHeight) / 2;
      // 记录底图左上角原点位置
      this.sourceImage.sourceX = this.sourceImage.x = dx;
      this.sourceImage.sourceY = this.sourceImage.y = dy;
      // 记录底图中心点
      this.sourceImage.centerX = dx + dWidth / 2;
      this.sourceImage.centerY = dy + dHeight / 2;
      // 旋转角
      if (this.t["轨迹"]["旋转角"]) {
        this.sourceImage.rotateAngel = this.t["轨迹"]["旋转角"];
      }
      // 绘图
      this.context.drawImage(
        imageObj,
        this.sourceImage.x,
        this.sourceImage.y,
        dWidth,
        dHeight
      );
      this.drawPath();
    };
    imageObj.src = this.picSrc + "?t=" + new Date().getTime();
  }
  /**
   * @description 处理鼠标按下
   */
  private handleMouseDown(e: any) {
    this.handleDown(e, "pc端");
  }
  /**
   * @description 处理手指按下
   */
  private handleTouchStart(e: any) {
    this.handleDown(e, "移动端");
  }
  /**
   * @description 处理鼠标移动
   */
  private handleMouseMove(e: any) {
    this.handleMove(e, "pc端");
  }
  /**
   * @description 处理手指移动
   */
  private handleTouchMove(e: any) {
    this.handleMove(e, "移动端");
  }
  /**
   * @description 处理鼠标弹起
   */
  private handleMouseUp(e: any) {
    this.handleUp(e, "pc端");
  }
  /**
   * @description 处理手指弹起
   */
  private handleTouchEnd(e: any) {
    this.handleUp(e, "移动端");
  }
  /**
   * @description 处理鼠标（手指）按下
   */
  private handleDown(e: any, type: any) {
    if (
      this.model === "苔质" &&
      this.t["望舌苔"]["苔质面积占舌比"].is_labeled
    ) {
      this.$message.error("苔质面积已存在，请删除后重新绘制");
      return;
    }
    if (
      this.model === "胖瘦" &&
      this.t["望舌质"]["胖瘦"]["舌边距"] &&
      this.t["望舌质"]["胖瘦"]["嘴角距"]
    ) {
      this.$message.error("嘴角距和舌边距都已存在，请删除后重新绘制");
      return;
    }
    if (
      this.model === "舌态" &&
      this.t["望舌质"]["舌态"] &&
      this.t["望舌质"]["舌态"]["歪斜角度"]
    ) {
      this.$message.error("歪斜角度已存在，请删除后重新绘制");
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (type === "pc端") {
      if (this.model !== "放大镜" && this.model !== "舌苔局部放大图") {
        if (e.button === 0) {
          this.ifMouseLeftDown = true;
        } else {
          return;
        }
      } else {
        if (e.button === 0) {
          this.ifMouseLeftDown = true;
        } else if (e.button === 2) {
          this.ifMouseRightDown = true;
        } else {
          return;
        }
      }
    }
    let X: any;
    let Y: any;
    if (type === "pc端") {
      this.startPoint = {
        offsetX: e.offsetX,
        offsetY: e.offsetY,
      };
      X = e.offsetX;
      Y = e.offsetY;
    } else if (type === "移动端") {
      this.startPoint = {
        offsetX: e.touches[0].clientX - this.canvasClientX,
        offsetY: e.touches[0].clientY - this.canvasClientY,
      };
      X = e.touches[0].clientX - this.canvasClientX;
      Y = e.touches[0].clientY - this.canvasClientY;
    }
    switch (this.model) {
      case "瘀斑":
        this.context.save();
        this.context.beginPath();
        this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
        this.context.strokeStyle = this.yubanColor;
        this.tempPath = [];
        break;
      case "齿痕":
        this.context.save();
        this.context.beginPath();
        this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
        this.context.strokeStyle = this.chihenColor;
        this.tempPath = [];
        break;
      case "苔质":
        this.context.save();
        this.context.beginPath();
        this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
        this.context.strokeStyle = this.taizhiColor;
        this.tempPath = [];
        break;
      case "剥落":
        this.context.save();
        this.context.beginPath();
        this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
        this.context.strokeStyle = this.boluoColor;
        this.tempPath = [];
        break;
      case "裂纹":
        this.context.save();
        this.context.beginPath();
        this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
        this.context.strokeStyle = this.liewenColor;
        this.tempPath = [];
        break;
      case "胖瘦":
        if (!this.t["望舌质"]["胖瘦"]["嘴角距"]) {
          this.kuankuanbiModel = "嘴角距";
        } else {
          this.kuankuanbiModel = "舌边距";
        }
        this.context.save();
        this.context.strokeStyle = this.kuankuanbiColor;
        this.endPoint = {};
        break;
      case "放大镜":
        if (this.ifMouseLeftDown === true) {
          if (!this.ifMouseRightDown) {
            this.draw();
            this.context.save();
            this.context.drawImage(
              this.sourceImage.imgObj,
              (X - 50 - (this.canvas.width - this.sourceImage.width) / 2) /
                (this.sourceImage.width / this.sourceImage.originWidth),
              (Y - 50 - (this.canvas.height - this.sourceImage.height) / 2) /
                (this.sourceImage.height / this.sourceImage.originHeight),
              100 / (this.sourceImage.width / this.sourceImage.originWidth),
              100 / (this.sourceImage.height / this.sourceImage.originHeight),
              X - 100,
              Y - 100,
              200,
              200
            );
            this.context.restore();
          }
        }
        break;
      case "舌苔局部放大图":
        if (this.ifMouseLeftDown === true) {
          if (this.ifMouseRightDown) {
            if (
              this.t["望舌苔"]["舌苔局部放大图"]["舌苔局部放大图"] &&
              this.t["望舌苔"]["舌苔局部放大图"]["舌苔局部放大图"].length >= 3
            ) {
              this.$message.error(
                "对不起，您保存的舌苔局部放大图已经超过3张了，如需保存，请先删除当前保存的图片"
              );
              return;
            }
            const c = document.createElement("canvas");
            c.width = 100;
            c.height = 100;
            const cxt: any = c.getContext("2d");
            this.drawBlackBack().then(() => {
              this.draw();
              const originImgData = this.context.getImageData(
                X - 50,
                Y - 50,
                100,
                100
              );
              cxt.putImageData(originImgData, 0, 0);
              const dataurl = c.toDataURL();
              const blobData = dataUrlToBlob(dataurl);
              const fileName = new Date().getTime().toString();
              const uploadFilePath: any = "ds/tongue/" + fileName + ".png";
              try {
                // this.ossClient.put(uploadFilePath, blobData).then((result) => {
                //   this.$emit("jubushetai", result["url"]);
                // });
                const params: any = {
                  type: "tongue",
                  base64_image: dataurl,
                };
                UploadImage(this, params).then((data: any) => {
                  // this.$emit("jubushetai", data["image_url"]);
                  this.t["望舌苔"]["舌苔局部放大图"]["舌苔局部放大图"].push(
                    data["image_url"]
                  );
                });
              } catch (e) {
                console.log(e);
              }
            });
          } else {
            this.draw();
            this.context.save();
            this.context.drawImage(
              this.sourceImage.imgObj,
              (X - 50 - (this.canvas.width - this.sourceImage.width) / 2) /
                (this.sourceImage.width / this.sourceImage.originWidth),
              (Y - 50 - (this.canvas.height - this.sourceImage.height) / 2) /
                (this.sourceImage.height / this.sourceImage.originHeight),
              100 / (this.sourceImage.width / this.sourceImage.originWidth),
              100 / (this.sourceImage.height / this.sourceImage.originHeight),
              X - 100,
              Y - 100,
              200,
              200
            );
            this.context.restore();
          }
        }
        break;
      case "点刺":
        this.context.save();
        this.context.strokeStyle = this.dianciColor;
        this.endPoint = {};
        break;
      case "瘀点":
        this.context.save();
        this.context.strokeStyle = this.yudianColor;
        this.endPoint = {};
        break;
      case "舌态":
        // this.draw();
        // this.drawJixian();
        this.endPoint = {
          offsetX: X,
          offsetY: Y,
        };
        const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
        const blackHeight = (this.canvas.height - this.sourceImage.height) / 2;
        this.context.beginPath();
        this.context.strokeStyle = this.shetaiColor;
        this.context.moveTo(this.canvas.width / 2, 0);
        this.context.lineTo(X, Y);
        this.context.closePath();
        this.context.stroke();
        const tempEndPoint = JSON.parse(JSON.stringify(this.endPoint));
        tempEndPoint.offsetX -= blackWidth;
        tempEndPoint.offsetY -= blackHeight;
        if (!this.t["轨迹"]["舌态"]) {
          this.t["轨迹"]["舌态"] = {};
          this.t["轨迹"]["舌态"]["歪斜角度"] = {};
        }
        this.t["轨迹"]["舌态"]["歪斜角度"] = {
          endPoint: tempEndPoint,
          width: this.sourceImage.width,
          height: this.sourceImage.height,
        };
        // 计算2条直线的夹角
        const centerPoint = {
          offsetX: this.canvas.width / 2,
          offsetY: 0,
        };
        const jiaoPoint = {
          offsetX: X,
          offsetY: Y,
        };
        this.getJiaodu(jiaoPoint, centerPoint);
        this.drawPath();
        break;
    }
  }
  /**
   * @description 处理鼠标(手指)移动
   */
  private handleMove(e: any, type: any) {
    if (
      this.model === "苔质" &&
      this.t["望舌苔"]["苔质面积占舌比"].is_labeled
    ) {
      return;
    }
    if (
      this.model === "胖瘦" &&
      this.t["望舌质"]["胖瘦"]["舌边距"] &&
      this.t["望舌质"]["胖瘦"]["嘴角距"]
    ) {
      return;
    }
    // if (
    //   this.model === "舌态" &&
    //   this.t["望舌质"]["舌态"] &&
    //   this.t["望舌质"]["舌态"]["歪斜角度"]
    // ) {
    //   return;
    // }
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (!this.startPoint.offsetX || !this.model) {
      return;
    }
    if (type === "pc端") {
      if (!this.ifMouseLeftDown) {
        return;
      }
    }
    let X: any;
    let Y: any;
    if (type === "pc端") {
      X = e.offsetX;
      Y = e.offsetY;
    } else if (type === "移动端") {
      X = e.touches[0].clientX - this.canvasClientX;
      Y = e.touches[0].clientY - this.canvasClientY;
    }
    this.drawBlackBack().then(() => {
      const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
      const blackHeight = (this.canvas.height - this.sourceImage.height) / 2;
      switch (this.model) {
        case "瘀斑":
          this.draw();
          this.context.lineTo(X, Y);
          this.tempPath.push({
            offsetX: X - blackWidth,
            offsetY: Y - blackHeight,
          });
          this.context.stroke();
          break;
        case "齿痕":
          this.draw();
          this.context.lineTo(X, Y);
          this.tempPath.push({
            offsetX: X - blackWidth,
            offsetY: Y - blackHeight,
          });
          this.context.stroke();
          break;
        case "苔质":
          this.draw();
          this.context.lineTo(X, Y);
          this.tempPath.push({
            offsetX: X - blackWidth,
            offsetY: Y - blackHeight,
          });
          this.context.stroke();
          break;
        case "剥落":
          this.draw();
          this.context.lineTo(X, Y);
          this.tempPath.push({
            offsetX: X - blackWidth,
            offsetY: Y - blackHeight,
          });
          this.context.stroke();
          break;
        case "裂纹":
          this.draw();
          this.context.lineTo(X, Y);
          this.tempPath.push({
            offsetX: X - blackWidth,
            offsetY: Y - blackHeight,
          });
          this.context.stroke();
          break;
        case "胖瘦":
          this.draw();
          this.context.beginPath();
          this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
          this.context.lineTo(X, Y);
          this.context.stroke();
          this.context.closePath();
          this.endPoint = {
            offsetX: X,
            offsetY: Y,
          };
          break;
        case "舌态":
          this.draw();
          this.drawJixian();
          this.context.beginPath();
          this.context.moveTo(this.canvas.width / 2, 0);
          this.context.lineTo(X, Y);
          this.context.stroke();
          this.context.closePath();
          this.endPoint = {
            offsetX: X,
            offsetY: Y,
          };
          this.context.beginPath();
          this.context.strokeStyle = this.shetaiColor;
          this.context.moveTo(this.canvas.width / 2, 0);
          this.context.lineTo(X, Y);
          this.context.closePath();
          this.context.stroke();
          const tempEndPoint = JSON.parse(JSON.stringify(this.endPoint));
          tempEndPoint.offsetX -= blackWidth;
          tempEndPoint.offsetY -= blackHeight;
          if (!this.t["轨迹"]["舌态"]) {
            this.t["轨迹"]["舌态"] = {};
            this.t["轨迹"]["舌态"]["歪斜角度"] = {};
          }
          this.t["轨迹"]["舌态"]["歪斜角度"] = {
            endPoint: tempEndPoint,
            width: this.sourceImage.width,
            height: this.sourceImage.height,
          };
          // 计算2条直线的夹角
          const centerPoint = {
            offsetX: this.canvas.width / 2,
            offsetY: 0,
          };
          const jiaoPoint: any = {
            offsetX: X,
            offsetY: Y,
          };
          this.getJiaodu(jiaoPoint, centerPoint);
          this.drawPath();
          break;
        case "移动":
          if (type === "pc端") {
            this.sourceImage.moveX += e.movementX;
            this.sourceImage.moveY += e.movementY;
          } else if (type === "移动端") {
            const movementX = e.touches[0].clientX - this.startPoint.offsetX;
            const movementY = e.touches[0].clientY - this.startPoint.offsetY;
            this.sourceImage.moveX = movementX;
            this.sourceImage.moveY = movementY;
          }
          this.draw();
          this.drawPath();
          break;
        case "缩放":
          const bigSpeed = 1.01;
          const smallSpeed = 0.99;
          let targetX;
          // let targetY;
          if (type === "pc端") {
            if (e.movementX > 0) {
              this.sourceImage.scaleX *= bigSpeed;
              targetX = bigSpeed;
            } else {
              this.sourceImage.scaleX *= smallSpeed;
              targetX = smallSpeed;
            }
          } else if (type === "移动端") {
            if (
              e.touches[0].clientY - this.canvasClientY >
              this.startPoint.offsetY
            ) {
              this.sourceImage.scaleX *= bigSpeed;
              targetX = bigSpeed;
            } else {
              this.sourceImage.scaleX *= smallSpeed;
              targetX = smallSpeed;
            }
          }
          this.sourceImage.scaleY = this.sourceImage.scaleX;
          this.draw();
          this.drawPath();
          break;
        case "放大镜":
          this.draw();
          this.context.save();
          this.context.drawImage(
            this.sourceImage.imgObj,
            (X - 50 - (this.canvas.width - this.sourceImage.width) / 2) /
              (this.sourceImage.width / this.sourceImage.originWidth),
            (Y - 50 - (this.canvas.height - this.sourceImage.height) / 2) /
              (this.sourceImage.height / this.sourceImage.originHeight),
            100 / (this.sourceImage.width / this.sourceImage.originWidth),
            100 / (this.sourceImage.height / this.sourceImage.originHeight),
            X - 100,
            Y - 100,
            200,
            200
          );
          this.context.restore();
          break;
        case "舌苔局部放大图":
          this.draw();
          this.context.save();
          this.context.drawImage(
            this.sourceImage.imgObj,
            (X - 50 - (this.canvas.width - this.sourceImage.width) / 2) /
              (this.sourceImage.width / this.sourceImage.originWidth),
            (Y - 50 - (this.canvas.height - this.sourceImage.height) / 2) /
              (this.sourceImage.height / this.sourceImage.originHeight),
            100 / (this.sourceImage.width / this.sourceImage.originWidth),
            100 / (this.sourceImage.height / this.sourceImage.originHeight),
            X - 100,
            Y - 100,
            200,
            200
          );
          this.context.restore();
          break;
        case "旋转":
          let rotateAngel = Math.PI / (6 * 1);
          if (e.movementY < 0) {
            rotateAngel = -rotateAngel;
          } else if (e.movementY === 0) {
            rotateAngel = 0;
          }
          this.sourceImage.rotateAngel += rotateAngel;
          this.t["轨迹"]["旋转角"] = this.sourceImage.rotateAngel;
          this.draw();
          this.drawPath();
          break;
        default:
          this.draw();
          this.drawPath();
      }
    });
  }
  /**
   * @description 处理鼠标（手指）弹起
   */
  private handleUp(e: any, type: any) {
    if (
      this.model === "苔质" &&
      this.t["望舌苔"]["苔质面积占舌比"].is_labeled
    ) {
      return;
    }
    if (
      this.model === "胖瘦" &&
      this.t["望舌质"]["胖瘦"]["舌边距"] &&
      this.t["望舌质"]["胖瘦"]["嘴角距"]
    ) {
      return;
    }
    // if (
    //   this.model === "舌态" &&
    //   this.t["望舌质"]["舌态"] &&
    //   this.t["望舌质"]["舌态"]["歪斜角度"]
    // ) {
    //   return;
    // }
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (type === "pc端") {
      if (!this.ifMouseLeftDown) {
        return;
      }
    }
    const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
    const blackHeight = (this.canvas.height - this.sourceImage.height) / 2;
    let X: any;
    let Y: any;
    switch (this.model) {
      case "瘀斑":
        this.context.closePath();
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          this.context.save();
          this.drawBlackBack().then(async () => {
            this.context.clip();
            this.draw();
            const area = this.calDrawedArea();
            const imgData = (this.canvas as HTMLCanvasElement).toDataURL(
              "image/jpeg",
              1.0
            );
            if (!Array.isArray(this.t["轨迹"]["瘀斑"])) {
              this.t["轨迹"]["瘀斑"] = [];
            }
            this.t["轨迹"]["瘀斑"].push({
              startPoint: {
                offsetX: tempStartPoint.offsetX,
                offsetY: tempStartPoint.offsetY,
              },
              path: this.tempPath,
              width: this.sourceImage.width,
              height: this.sourceImage.height,
              area: (Number(area) / 100) * this.cuttedArea,
            });
            const arr: any[] = [];
            const orArr: any[] = [];
            let totalArea: any = 0;
            this.t["轨迹"]["瘀斑"].forEach((item: any) => {
              arr.push({
                area: item["area"].toFixed(4) + "px²",
              });
              orArr.push(item["area"].toFixed(4));
              totalArea += item["area"];
            });
            const min = Math.min(...orArr);
            const max = Math.max(...orArr);
            const data: any = JSON.parse(
              JSON.stringify(this.t["望舌质"]["瘀斑"])
            );
            data["is_labeled"] = true;
            data["瘀斑数量"] = arr.length;
            data["瘀斑数据"] = arr;
            data["瘀斑最小面积占舌比"] = (min / this.cuttedArea).toFixed(4);
            data["瘀斑最大面积占舌比"] = (max / this.cuttedArea).toFixed(4);
            data["瘀斑平均面积占舌比"] = (
              totalArea /
              orArr.length /
              this.cuttedArea
            ).toFixed(4);
            this.t["望舌质"]["瘀斑"] = data;
            this.tempPath = [];
            this.startPoint = {};
            this.context.restore();
            this.draw();
            this.drawPath();
          });
        } else {
          this.context.closePath();
          this.context.stroke();
          this.tempPath = [];
          this.startPoint = {};
        }
        this.context.restore();
        break;
      case "齿痕":
        this.context.closePath();
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          this.context.save();
          this.drawBlackBack().then(async () => {
            this.context.clip();
            this.draw();
            const area = this.calDrawedArea();
            const imgData = (this.canvas as HTMLCanvasElement).toDataURL(
              "image/jpeg",
              1.0
            );
            if (!Array.isArray(this.t["轨迹"]["齿痕"])) {
              this.t["轨迹"]["齿痕"] = [];
            }
            this.t["轨迹"]["齿痕"].push({
              startPoint: {
                offsetX: tempStartPoint.offsetX,
                offsetY: tempStartPoint.offsetY,
              },
              path: this.tempPath,
              width: this.sourceImage.width,
              height: this.sourceImage.height,
              area: (Number(area) / 100) * this.cuttedArea,
            });
            const arr: any[] = [];
            const orArr: any[] = [];
            let totalArea: any = 0;
            this.t["轨迹"]["齿痕"].forEach((item: any) => {
              arr.push({
                area: item["area"].toFixed(4) + "px²",
              });
              orArr.push(Number(item["area"].toFixed(4)));
              totalArea += item["area"];
            });
            const min = Math.min(...orArr);
            const max = Math.max(...orArr);
            const data: any = JSON.parse(
              JSON.stringify(this.t["望舌质"]["齿痕"])
            );
            data["is_labeled"] = true;
            data["齿痕数量"] = arr.length;
            data["齿痕数据"] = arr;
            data["齿痕最小面积占舌比"] = (min / this.cuttedArea).toFixed(4);
            data["齿痕最大面积占舌比"] = (max / this.cuttedArea).toFixed(4);
            data["齿痕平均面积占舌比"] = (
              totalArea /
              orArr.length /
              this.cuttedArea
            ).toFixed(4);
            this.t["望舌质"]["齿痕"] = data;
            this.tempPath = [];
            this.startPoint = {};
            this.context.restore();
            this.draw();
            this.drawPath();
          });
        } else {
          this.context.closePath();
          this.context.stroke();
          this.tempPath = [];
          this.startPoint = {};
        }
        this.context.restore();
        break;
      case "苔质":
        this.context.closePath();
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          this.context.save();
          this.drawBlackBack().then(async () => {
            this.context.clip();
            this.draw();
            const area = this.calDrawedArea();
            const imgData = (this.canvas as HTMLCanvasElement).toDataURL(
              "image/jpeg",
              1.0
            );
            if (!Array.isArray(this.t["轨迹"]["苔质"])) {
              this.t["轨迹"]["苔质"] = [];
            }
            this.t["轨迹"]["苔质"].push({
              startPoint: {
                offsetX: tempStartPoint.offsetX,
                offsetY: tempStartPoint.offsetY,
              },
              path: this.tempPath,
              width: this.sourceImage.width,
              height: this.sourceImage.height,
              area: (Number(area) / 100) * this.cuttedArea,
            });
            const arr: any[] = [];
            const orArr: any[] = [];
            let totalArea: any = 0;
            this.t["轨迹"]["苔质"].forEach((item: any) => {
              arr.push({
                area: item["area"].toFixed(2) + "px²",
              });
              orArr.push(Number(item["area"].toFixed(2)));
              totalArea += item["area"];
            });
            const data: any = JSON.parse(
              JSON.stringify(this.t["望舌苔"]["苔质面积占舌比"])
            );
            data["is_labeled"] = true;
            data["苔质数据"] = arr;
            data["苔质面积"] = orArr[0].toFixed(2);
            data["苔质面积占舌比"] = (orArr[0] / this.cuttedArea).toFixed(4);
            this.t["望舌苔"]["苔质面积占舌比"] = data;
            this.tempPath = [];
            this.startPoint = {};
            this.context.restore();
            this.draw();
            this.drawPath();
          });
        } else {
          this.context.closePath();
          this.context.stroke();
          this.tempPath = [];
          this.startPoint = {};
        }
        this.context.restore();
        break;
      case "剥落":
        this.context.closePath();
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          this.context.save();
          this.drawBlackBack().then(async () => {
            this.context.clip();
            this.draw();
            const area = this.calDrawedArea();
            const imgData = (this.canvas as HTMLCanvasElement).toDataURL(
              "image/jpeg",
              1.0
            );
            if (!Array.isArray(this.t["轨迹"]["剥落"])) {
              this.t["轨迹"]["剥落"] = [];
            }
            this.t["轨迹"]["剥落"].push({
              startPoint: {
                offsetX: tempStartPoint.offsetX,
                offsetY: tempStartPoint.offsetY,
              },
              path: this.tempPath,
              width: this.sourceImage.width,
              height: this.sourceImage.height,
              area: (Number(area) / 100) * this.cuttedArea,
            });
            const arr: any[] = [];
            const orArr: any[] = [];
            let totalArea: any = 0;
            this.t["轨迹"]["剥落"].forEach((item: any) => {
              arr.push({
                area: item["area"].toFixed(2) + "px²",
              });
              orArr.push(Number(item["area"].toFixed(2)));
              totalArea += item["area"];
            });
            const data: any = JSON.parse(
              JSON.stringify(this.t["望舌苔"]["剥落"])
            );
            data["is_labeled"] = true;
            data["剥落数据"] = arr;
            data["剥落面积占舌比"] = (totalArea / this.cuttedArea).toFixed(4);
            this.t["望舌苔"]["剥落"] = data;
            this.tempPath = [];
            this.startPoint = {};
            this.context.restore();
            this.draw();
            this.drawPath();
          });
        } else {
          this.context.closePath();
          this.context.stroke();
          this.tempPath = [];
          this.startPoint = {};
        }
        this.context.restore();
        break;
      case "裂纹":
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          this.context.save();
          this.drawBlackBack().then(async () => {
            if (!Array.isArray(this.t["轨迹"]["裂纹"])) {
              this.t["轨迹"]["裂纹"] = [];
            }
            this.t["轨迹"]["裂纹"].push({
              startPoint: {
                offsetX: tempStartPoint.offsetX,
                offsetY: tempStartPoint.offsetY,
              },
              path: this.tempPath,
              width: this.sourceImage.width,
              height: this.sourceImage.height,
              length: this.tempPath.length,
            });
            const arr: any[] = [];
            const orArr: any[] = [];
            let totalLength: any = 0;
            this.t["轨迹"]["裂纹"].forEach((item: any) => {
              arr.push({
                length: item["length"] + "px",
              });
              orArr.push(item["length"]);
              totalLength += item["length"];
            });
            const min = Math.min(...orArr);
            const max = Math.max(...orArr);
            const data: any = JSON.parse(
              JSON.stringify(this.t["望舌质"]["裂纹"])
            );
            data["is_labeled"] = true;
            data["裂纹数量"] = arr.length;
            data["裂纹数据"] = arr;
            data["裂纹总像素长度"] = totalLength;
            data["裂纹最大像素长度"] = max;
            data["裂纹最小像素长度"] = min;
            data["裂纹平均像素长度"] = (totalLength / arr.length).toFixed(0);
            this.t["望舌质"]["裂纹"] = data;
            this.tempPath = [];
            this.startPoint = {};
            this.context.restore();
            this.draw();
            this.drawPath();
          });
        } else {
          // this.context.closePath();
          // this.context.stroke();
          this.tempPath = [];
          this.startPoint = {};
        }
        this.context.restore();
        break;
      case "点刺":
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          this.context.save();
          this.drawBlackBack().then(async () => {
            if (!Array.isArray(this.t["轨迹"]["点刺"])) {
              this.t["轨迹"]["点刺"] = [];
            }
            this.t["轨迹"]["点刺"].push({
              startPoint: {
                offsetX: tempStartPoint.offsetX,
                offsetY: tempStartPoint.offsetY,
              },
              width: this.sourceImage.width,
              height: this.sourceImage.height,
            });
            const data: any = JSON.parse(
              JSON.stringify(this.t["望舌质"]["点刺"])
            );
            data["is_labeled"] = true;
            data["点刺数量"] = this.t["轨迹"]["点刺"].length;
            data["点刺数据"] = JSON.parse(
              JSON.stringify(this.t["轨迹"]["点刺"])
            );
            this.t["望舌质"]["点刺"] = data;
            this.tempPath = [];
            this.startPoint = {};
            this.context.restore();
            this.draw();
            this.drawPath();
          });
        } else {
          // this.context.closePath();
          // this.context.stroke();
          this.tempPath = [];
          this.startPoint = {};
        }
        this.context.restore();
        break;
      case "瘀点":
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          this.context.save();
          this.drawBlackBack().then(async () => {
            if (!Array.isArray(this.t["轨迹"]["瘀点"])) {
              this.t["轨迹"]["瘀点"] = [];
            }
            this.t["轨迹"]["瘀点"].push({
              startPoint: {
                offsetX: tempStartPoint.offsetX,
                offsetY: tempStartPoint.offsetY,
              },
              width: this.sourceImage.width,
              height: this.sourceImage.height,
            });
            const data: any = JSON.parse(
              JSON.stringify(this.t["望舌质"]["瘀点"])
            );
            data["is_labeled"] = true;
            data["瘀点数量"] = this.t["轨迹"]["瘀点"].length;
            data["瘀点数据"] = JSON.parse(
              JSON.stringify(this.t["轨迹"]["瘀点"])
            );
            this.t["望舌质"]["瘀点"] = data;
            this.tempPath = [];
            this.startPoint = {};
            this.context.restore();
            this.draw();
            this.drawPath();
          });
        } else {
          // this.context.closePath();
          // this.context.stroke();
          this.tempPath = [];
          this.startPoint = {};
        }
        this.context.restore();
        break;
      case "胖瘦":
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          const tempEndPoint = JSON.parse(JSON.stringify(this.endPoint));
          tempEndPoint.offsetX -= blackWidth;
          tempEndPoint.offsetY -= blackHeight;
          this.t["轨迹"]["胖瘦"][this.kuankuanbiModel] = {
            startPoint: tempStartPoint,
            endPoint: tempEndPoint,
            width: this.sourceImage.width,
            height: this.sourceImage.height,
          };
          const data: any = JSON.parse(
            JSON.stringify(this.t["望舌质"]["胖瘦"])
          );
          data[this.kuankuanbiModel] = this.calLineLength(
            tempStartPoint,
            tempEndPoint
          ).toFixed(2);
          data["is_labeled"] = true;
          this.startPoint = {};
          this.endPoint = {};
          this.drawPath();
          if (data["嘴角距"] && data["舌边距"]) {
            data["宽宽比"] = (data["嘴角距"] / data["舌边距"]).toFixed(3);
          }
          this.t["望舌质"]["胖瘦"] = data;
        }

        break;
      case "舌态":
        if (this.picType === "origin") {
          const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
          tempStartPoint.offsetX -= blackWidth;
          tempStartPoint.offsetY -= blackHeight;
          const tempEndPoint = JSON.parse(JSON.stringify(this.endPoint));
          tempEndPoint.offsetX -= blackWidth;
          tempEndPoint.offsetY -= blackHeight;
          if (!this.t["轨迹"]["舌态"]) {
            this.t["轨迹"]["舌态"] = {};
            this.t["轨迹"]["舌态"]["歪斜角度"] = {};
          }
          this.t["轨迹"]["舌态"]["歪斜角度"] = {
            endPoint: tempEndPoint,
            width: this.sourceImage.width,
            height: this.sourceImage.height,
          };
          const data: any = JSON.parse(
            JSON.stringify(this.t["望舌质"]["舌态"])
          );
          // 计算2条直线的夹角
          const centerPoint = {
            offsetX: this.canvas.width / 2,
            offsetY: 0,
          };
          if (type === "pc端") {
            X = e.offsetX;
            Y = e.offsetY;
          } else if (type === "移动端") {
            X = e.touches[0].clientX - this.canvasClientX;
            Y = e.touches[0].clientY - this.canvasClientY;
          }
          const jiaoPoint = {
            offsetX: X,
            offsetY: Y,
          };
          data["is_labeled"] = true;
          data["歪斜角度"] = this.getJiaodu(jiaoPoint, centerPoint);
          this.startPoint = {};
          this.endPoint = {};
          this.t["望舌质"]["舌态"] = data;
        }

        break;
      case "放大镜":
        this.drawBlackBack().then(() => {
          this.draw();
          this.drawPath();
        });

        break;
      case "舌苔局部放大图":
        this.drawBlackBack().then(() => {
          this.draw();
          this.drawPath();
        });

        break;
    }
    this.startPoint.offsetX = 0;
    this.startPoint.offsetY = 0;
    if (type === "pc端") {
      this.ifMouseLeftDown = false;
    }
    if (type === "pc端" && this.ifMouseRightDown) {
      this.ifMouseRightDown = false;
    }
  }
  /**
   * @description 处理鼠标右键弹出菜单
   */
  private handleContextMenu(e: any) {
    if (this.model === "放大镜" || this.model === "舌苔局部放大图") {
      e.preventDefault();
    }
  }
  /**
   * @description 计算两点连线的线段长
   */
  private calLineLength(point1: any, point2: any) {
    const value1 = Math.pow(point2.offsetX - point1.offsetX, 2);
    const value2 = Math.pow(point2.offsetY - point1.offsetY, 2);
    return Math.pow(value1 + value2, 0.5);
  }
  /**
   * @description 计算当前绘制面积
   */
  private calDrawedArea() {
    const imgData = this.context.getImageData(
      this.sourceImage.x,
      this.sourceImage.y,
      this.sourceImage.width,
      this.sourceImage.height
    );
    const pixels = imgData.data;
    const transPixels: any[] = [];
    for (let i = 0; i < pixels.length; i += 4) {
      if (!(pixels[i] === 0 && pixels[i + 1] === 0 && pixels[i + 2] === 0)) {
        transPixels.push(pixels[i + 3]);
      }
    }
    const area = ((transPixels.length / (pixels.length / 4)) * 100).toFixed(2);
    return area;
  }
  /**
   * @description 计算2条直线的夹角
   */
  private getJiaodu(point: any, centerPoint: any) {
    // 求出生成三角形的第三个点
    const point2: any = {
      offsetX: centerPoint.offsetX,
      offsetY: point.offsetY,
    };
    const maxLength = this.calLineLength(point, centerPoint);
    const minLength = this.calLineLength(point2, point);
    const arc = Math.asin(Number(minLength) / Number(maxLength));
    const angle = (arc * 180) / Math.PI;
    const part = Math.PI / 180;
    // 画弧
    this.context.beginPath();
    this.context.lineWidth = 1;
    this.context.strokeStyle = this.shetaiColor;
    if (point2.offsetX - point.offsetX > 0) {
      this.context.arc(
        centerPoint.offsetX,
        centerPoint.offsetY,
        maxLength / 2,
        part * 90,
        part * (90 + angle)
      );
    } else {
      this.context.arc(
        centerPoint.offsetX,
        centerPoint.offsetY,
        maxLength / 2,
        part * (90 - angle),
        part * 90
      );
    }

    this.context.stroke();
    this.context.closePath();
    return angle.toFixed(2);
  }
  /**
   * @description 局部舌色
   */
  private jubushese() {
    this.model = "局部舌色";
  }
  /**
   * @description 删除局部舌色
   */
  private deleteJubushese() {
    delete this.t["轨迹"]["局部舌色"];
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
  }
  /**
   * @description 局部苔色
   */
  private jubutaise() {
    this.model = "局部苔色";
  }
  /**
   * @description 删除局部苔色
   */
  private deleteJubutaise() {
    delete this.t["轨迹"]["局部苔色"];
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
  }
  /**
   * @description 舌态
   */
  private shetai() {
    this.model = "舌态";
    // 画基线
    this.drawJixian();
  }
  /**
   * @description 删除舌态
   */
  private deleteShetai() {
    delete this.t["轨迹"]["舌态"];
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawJixian();
      this.drawPath();
    });

    const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["舌态"]));
    data["is_labeled"] = false;
    data["歪斜角度"] = null;
    this.t["望舌质"]["舌态"] = data;
  }
  /**
   * @description 舌上红点
   */
  private sheshanghongdian() {
    this.model = "舌上红点";
  }
  /**
   * @description 删除舌上红点
   */
  private deleteSheshanghongdian() {
    delete this.t["轨迹"]["舌上红点"];
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
  }
  /**
   * @description 瘀斑
   */
  private yuban() {
    this.model = "瘀斑";
  }
  /**
   * @description 删除某个瘀斑
   */
  private deleteYuban(index: any) {
    this.t["轨迹"]["瘀斑"].splice(index, 1);
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
    const arr: any[] = [];
    const orArr: any[] = [];
    let totalArea: any = 0;
    this.t["轨迹"]["瘀斑"].forEach((item: any) => {
      arr.push({
        area: item["area"].toFixed(2) + "px²",
      });
      orArr.push(item["area"].toFixed(2));
      totalArea += item["area"];
    });
    const min = Math.min(...orArr);
    const max = Math.max(...orArr);
    const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["瘀斑"]));
    data["is_labeled"] = true;
    data["瘀斑数量"] = arr.length;
    data["瘀斑数据"] = arr;
    data["瘀斑最小面积占舌比"] = (min / this.cuttedArea).toFixed(4);
    data["瘀斑最大面积占舌比"] = (max / this.cuttedArea).toFixed(4);
    data["瘀斑平均面积占舌比"] = (
      totalArea /
      orArr.length /
      this.cuttedArea
    ).toFixed(4);
    if (arr.length === 0) {
      data.is_labeled = false;
      data["瘀斑最小面积占舌比"] = 0;
      data["瘀斑最大面积占舌比"] = 0;
      data["瘀斑平均面积占舌比"] = 0;
    }
    this.t["望舌质"]["瘀斑"] = data;
  }
  /**
   * @description 齿痕
   */
  private chihen() {
    this.model = "齿痕";
  }
  /**
   * @description 删除某个齿痕
   */
  private deleteChihen(index: any) {
    this.t["轨迹"]["齿痕"].splice(index, 1);
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
    const arr: any[] = [];
    const orArr: any[] = [];
    let totalArea: any = 0;
    this.t["轨迹"]["齿痕"].forEach((item: any) => {
      arr.push({
        area: item["area"].toFixed(2) + "px²",
      });
      orArr.push(item["area"].toFixed(2));
      totalArea += item["area"];
    });
    const min = Math.min(...orArr);
    const max = Math.max(...orArr);
    const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["齿痕"]));
    data["is_labeled"] = true;
    data["齿痕数量"] = arr.length;
    data["齿痕数据"] = arr;
    data["齿痕最小面积占舌比"] = (min / this.cuttedArea).toFixed(4);
    data["齿痕最大面积占舌比"] = (max / this.cuttedArea).toFixed(4);
    data["齿痕平均面积占舌比"] = (
      totalArea /
      orArr.length /
      this.cuttedArea
    ).toFixed(4);
    if (arr.length === 0) {
      data.is_labeled = false;
      data["齿痕最小面积占舌比"] = 0;
      data["齿痕最大面积占舌比"] = 0;
      data["齿痕平均面积占舌比"] = 0;
    }
    this.t["望舌质"]["齿痕"] = data;
  }
  /**
   * @description 裂纹
   */
  private liewen() {
    this.model = "裂纹";
  }
  /**
   * @description 删除某个裂纹
   */
  private deleteLiewen(index: any) {
    this.t["轨迹"]["裂纹"].splice(index, 1);
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
    const arr: any[] = [];
    const orArr: any[] = [];
    let totalLength: any = 0;
    this.t["轨迹"]["裂纹"].forEach((item: any) => {
      arr.push({
        length: item["length"] + "px",
      });
      orArr.push(item["length"]);
      totalLength += item["length"];
    });
    const min = Math.min(...orArr);
    const max = Math.max(...orArr);
    const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["裂纹"]));
    data["is_labeled"] = true;
    data["裂纹数量"] = arr.length;
    data["裂纹数据"] = arr;
    data["裂纹总像素长度"] = totalLength;
    data["裂纹最大像素长度"] = max;
    data["裂纹最小像素长度"] = min;
    data["裂纹平均像素长度"] = (totalLength / arr.length).toFixed(0);
    if (arr.length === 0) {
      data.is_labeled = false;
      data["裂纹总像素长度"] = 0;
      data["裂纹最大像素长度"] = 0;
      data["裂纹最小像素长度"] = 0;
      data["裂纹平均像素长度"] = 0;
    }
    this.t["望舌质"]["裂纹"] = data;
  }
  /**
   * @description 胖瘦
   */
  private pangshou() {
    this.model = "胖瘦";
  }
  /**
   * @description 删除胖瘦某条线
   */
  private deletePangshou(part: any) {
    const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["胖瘦"]));
    data[part] = 0;
    data["宽宽比"] = 0;
    if (!data["嘴角距"] && !data["舌边距"]) {
      data["is_labeled"] = false;
    }
    this.t["望舌质"]["胖瘦"] = data;
    this.t["轨迹"]["胖瘦"][part] = {};
    this.model = "胖瘦";
    this.$emit("modelChange", "胖瘦");
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
  }
  /**
   * @description 苔质
   */
  private taizhi() {
    this.model = "苔质";
  }
  /**
   * @description 删除某个苔质
   */
  private deleteTaizhi(index: any) {
    this.t["轨迹"]["苔质"].splice(index, 1);
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
    const arr: any[] = [];
    const orArr: any[] = [];
    let totalArea: any = 0;
    this.t["轨迹"]["苔质"].forEach((item: any) => {
      arr.push({
        area: item["area"].toFixed(2) + "px²",
      });
      orArr.push(item["area"].toFixed(2));
      totalArea += item["area"];
    });
    const data: any = JSON.parse(
      JSON.stringify(this.t["望舌苔"]["苔质面积占舌比"])
    );
    data["is_labeled"] = true;
    data["苔质数据"] = arr;
    data["苔质面积"] = orArr[0];
    data["苔质面积占舌比"] = (totalArea / this.cuttedArea).toFixed(4);
    if (arr.length === 0) {
      data.is_labeled = false;
      data["苔质面积"] = 0;
      data["苔质面积占舌比"] = 0;
    }
    this.t["望舌苔"]["苔质面积占舌比"] = data;
  }
  /**
   * @description 剥落
   */
  private boluo() {
    this.model = "剥落";
  }
  /**
   * @description 删除某个剥落
   */
  private deleteBoluo(index: any) {
    this.t["轨迹"]["剥落"].splice(index, 1);
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
    const arr: any[] = [];
    const orArr: any[] = [];
    let totalArea: any = 0;
    this.t["轨迹"]["剥落"].forEach((item: any) => {
      arr.push({
        area: item["area"].toFixed(2) + "px²",
      });
      orArr.push(item["area"].toFixed(2));
      totalArea += item["area"];
    });
    const data: any = JSON.parse(JSON.stringify(this.t["望舌苔"]["剥落"]));
    data["is_labeled"] = true;
    data["剥落数据"] = arr;
    data["剥落面积占舌比"] = (totalArea / this.cuttedArea).toFixed(4);
    if (arr.length === 0) {
      data.is_labeled = false;
      data["剥落面积占舌比"] = 0;
    }
    this.t["望舌苔"]["剥落"] = data;
  }
  /**
   * @description 舌苔局部放大图
   */
  private jubushetai() {
    this.model = "舌苔局部放大图";
  }
  /**
   * @description 删除舌苔局部放大图
   */
  private deleteJubushetai(index: any) {
    this.t["望舌苔"]["舌苔局部放大图"]["舌苔局部放大图"].splice(index, 1);
  }
  /**
   * @description 点刺
   */
  private dianci() {
    this.model = "点刺";
  }
  /**
   * @description 删除某个点刺
   */
  private deleteDianci(index: any) {
    this.t["轨迹"]["点刺"].splice(index, 1);
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
    const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["点刺"]));
    data["is_labeled"] = true;
    data["点刺数量"] = this.t["轨迹"]["点刺"].length;
    data["点刺数据"] = JSON.parse(JSON.stringify(this.t["轨迹"]["点刺"]));
    this.t["望舌质"]["点刺"] = data;
  }
  /**
   * @description 瘀点
   */
  private yudian() {
    this.model = "瘀点";
  }
  /**
   * @description 删除某个瘀点
   */
  private deleteYudian(index: any) {
    this.t["轨迹"]["瘀点"].splice(index, 1);
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
    const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["瘀点"]));
    data["is_labeled"] = true;
    data["瘀点数量"] = this.t["轨迹"]["瘀点"].length;
    data["瘀点数据"] = JSON.parse(JSON.stringify(this.t["轨迹"]["瘀点"]));
    this.t["望舌质"]["瘀点"] = data;
  }
  /**
   * @description 移动
   */
  private yidong() {
    this.model = "移动";
  }
  /**
   * @description 缩放
   */
  private suofang() {
    this.model = "缩放";
  }
  /**
   * @description 放大镜
   */
  private fangdajing() {
    this.model = "放大镜";
  }
  /**
   * @description 旋转
   */
  private xuanzhuan() {
    this.model = "旋转";
  }
  /**
   * @description 截屏
   */
  private jieping() {
    this.model = "截屏";
    html2canvas(this.canvasWrap).then((canvas: HTMLCanvasElement) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const i = document.createElement("a");
      i.download = "舌象图片";
      i.href = imgData;
      document.body.appendChild(i);
      i.click();
      i.remove();
    });
  }
  /**
   * @description 还原
   */
  private huanyuan() {
    this.model = "还原";
    this.sourceImage.rotateAngel = 0;
    this.sourceImage.moveX = 0;
    this.sourceImage.moveY = 0;
    this.sourceImage.scaleX = 1;
    this.sourceImage.scaleY = 1;
    this.t["轨迹"] = {};
    this.initCanvas();
  }
  /**
   * @description 画舌态的基线
   */
  private drawJixian() {
    // 画基线
    this.context.beginPath();
    this.context.strokeStyle = this.shetaiColor;
    this.context.moveTo(this.canvas.width / 2, 0);
    this.context.lineTo(this.canvas.width / 2, this.canvas.height);
    this.context.closePath();
    this.context.stroke();
    this.context.restore();
  }
  /**
   * @description 后续绘制
   */
  private draw() {
    this.context.drawImage(
      this.sourceImage.imgObj,
      this.sourceImage.x,
      this.sourceImage.y,
      this.sourceImage.width,
      this.sourceImage.height
    );
    // this.drawPath();
  }
  /**
   * @description 画轨迹
   */
  private drawPath() {
    if (this.cuttedArea && this.cuttedArea > 0) {
      this.drawPath2();
    } else {
      const params: any = {
        分割后舌图: this.tongue["舌象照片"]["舌面分割前图片"],
      };
      CalCuttedTongueArea(this, params).then((data: any) => {
        this.cuttedArea = data["area"];
        this.drawPath2();
      });
    }
  }
  private drawPath2() {
    if (this.t["轨迹"] && this.picType === "origin") {
      // 齿痕
      if (this.t["轨迹"]["齿痕"] && Array.isArray(this.t["轨迹"]["齿痕"])) {
        const arr: any[] = [];
        const orArr: any[] = [];
        let totalArea: any = 0;
        this.t["轨迹"]["齿痕"].forEach((item: any) => {
          // 如果齿痕有数据需要先生成相关显示字段
          arr.push({
            area: item["area"].toFixed(4) + "px²",
          });
          orArr.push(Number(item["area"].toFixed(4)));
          totalArea += item["area"];

          const d = item;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.strokeStyle = this.chihenColor;
          d.path.forEach((path: any) => {
            this.context.lineTo(
              path.offsetX * dw + blackWidth,
              path.offsetY * dh + blackHeight
            );
            this.context.stroke();
          });
          this.context.closePath();
          this.context.stroke();
          this.context.restore();
        });
        const min = Math.min(...orArr);
        const max = Math.max(...orArr);
        const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["齿痕"]));
        if (arr && arr.length > 0) {
          data["is_labeled"] = true;
          data["齿痕数量"] = arr.length;
          data["齿痕数据"] = arr;
          data["齿痕最小面积占舌比"] = (min / this.cuttedArea).toFixed(4);
          data["齿痕最大面积占舌比"] = (max / this.cuttedArea).toFixed(4);
          data["齿痕平均面积占舌比"] = (
            totalArea /
            orArr.length /
            this.cuttedArea
          ).toFixed(4);
        } else {
          data["is_labeled"] = false;
          data["齿痕数量"] = 0;
          data["齿痕最小面积占舌比"] = 0;
          data["齿痕最大面积占舌比"] = 0;
          data["齿痕平均面积占舌比"] = 0;
        }
        this.t["望舌质"]["齿痕"] = data;
      }
      // 裂纹
      if (this.t["轨迹"]["裂纹"] && Array.isArray(this.t["轨迹"]["裂纹"])) {
        const arr: any[] = [];
        const orArr: any[] = [];
        let totalLength: any = 0;
        this.t["轨迹"]["裂纹"].forEach((item: any) => {
          arr.push({
            length: item["length"] + "px",
          });
          orArr.push(item["length"]);
          totalLength += item["length"];
          const d = item;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.strokeStyle = this.liewenColor;
          d.path.forEach((path: any) => {
            this.context.lineTo(
              path.offsetX * dw + blackWidth,
              path.offsetY * dh + blackHeight
            );
            this.context.stroke();
          });
          this.context.restore();
        });
        const min = Math.min(...orArr);
        const max = Math.max(...orArr);
        const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["裂纹"]));
        if (arr.length > 0) {
          data["is_labeled"] = true;
          data["裂纹数量"] = arr.length;
          data["裂纹数据"] = arr;
          data["裂纹总像素长度"] = totalLength;
          data["裂纹最大像素长度"] = max;
          data["裂纹最小像素长度"] = min;
          data["裂纹平均像素长度"] = (totalLength / arr.length).toFixed(0);
        } else {
          data["is_labeled"] = false;
          data["裂纹数量"] = 0;
          data["裂纹总像素长度"] = 0;
          data["裂纹最大像素长度"] = 0;
          data["裂纹最小像素长度"] = 0;
          data["裂纹平均像素长度"] = 0;
        }
        this.t["望舌质"]["裂纹"] = data;
      }
      // 点刺
      if (this.t["轨迹"]["点刺"] && Array.isArray(this.t["轨迹"]["点刺"])) {
        this.t["轨迹"]["点刺"].forEach((dianci: any) => {
          const d = dianci;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.lineWidth = 2;
          this.context.arc(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight,
            4,
            0,
            Math.PI * 2
          );
          this.context.strokeStyle = this.dianciColor;
          this.context.stroke();
          this.context.fillStyle = this.dianciColor;
          this.context.fill();
          this.context.restore();
        });
        const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["点刺"]));
        if (this.t["轨迹"]["点刺"].length > 0) {
          data["is_labeled"] = true;
          data["点刺数量"] = this.t["轨迹"]["点刺"].length;
          data["点刺数据"] = JSON.parse(JSON.stringify(this.t["轨迹"]["点刺"]));
        } else {
          data["is_labeled"] = false;
          data["点刺数量"] = 0;
        }
        this.t["望舌质"]["点刺"] = data;
      }
      // 瘀点
      if (this.t["轨迹"]["瘀点"] && Array.isArray(this.t["轨迹"]["瘀点"])) {
        this.t["轨迹"]["瘀点"].forEach((dianci: any) => {
          const d = dianci;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.lineWidth = 2;
          this.context.arc(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight,
            4,
            0,
            Math.PI * 2
          );
          this.context.strokeStyle = this.yudianColor;
          this.context.stroke();
          this.context.fillStyle = this.yudianColor;
          this.context.fill();
          this.context.restore();
        });
        const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["瘀点"]));
        if (this.t["轨迹"]["瘀点"].length > 0) {
          data["is_labeled"] = true;
          data["瘀点数量"] = this.t["轨迹"]["瘀点"].length;
          data["瘀点数据"] = JSON.parse(JSON.stringify(this.t["轨迹"]["瘀点"]));
        } else {
          data["is_labeled"] = false;
          data["瘀点数量"] = 0;
        }
        this.t["望舌质"]["瘀点"] = data;
      }
      // 瘀斑
      if (this.t["轨迹"]["瘀斑"] && Array.isArray(this.t["轨迹"]["瘀斑"])) {
        const arr: any[] = [];
        const orArr: any[] = [];
        let totalArea: any = 0;
        this.t["轨迹"]["瘀斑"].forEach((item: any) => {
          arr.push({
            area: item["area"].toFixed(4) + "px²",
          });
          orArr.push(item["area"].toFixed(4));
          totalArea += item["area"];
          const d = item;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.strokeStyle = this.yubanColor;
          d.path.forEach((path: any) => {
            this.context.lineTo(
              path.offsetX * dw + blackWidth,
              path.offsetY * dh + blackHeight
            );
            this.context.stroke();
          });
          this.context.closePath();
          this.context.stroke();
          this.context.restore();
        });
        const min = Math.min(...orArr);
        const max = Math.max(...orArr);
        const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["瘀斑"]));
        if (arr && arr.length > 0) {
          data["is_labeled"] = true;
          data["瘀斑数量"] = arr.length;
          data["瘀斑数据"] = arr;
          data["瘀斑最小面积占舌比"] = (min / this.cuttedArea).toFixed(4);
          data["瘀斑最大面积占舌比"] = (max / this.cuttedArea).toFixed(4);
          data["瘀斑平均面积占舌比"] = (
            totalArea /
            orArr.length /
            this.cuttedArea
          ).toFixed(4);
        } else {
          data["is_labeled"] = false;
          data["瘀斑数量"] = 0;
          data["瘀斑最小面积占舌比"] = 0;
          data["瘀斑最大面积占舌比"] = 0;
          data["瘀斑平均面积占舌比"] = 0;
        }
        this.t["望舌质"]["瘀斑"] = data;
      }
      // 胖瘦
      if (this.t["轨迹"]["胖瘦"]) {
        if (
          this.t["轨迹"]["胖瘦"]["嘴角距"] &&
          this.t["轨迹"]["胖瘦"]["嘴角距"]["startPoint"] &&
          this.t["轨迹"]["胖瘦"]["嘴角距"]["endPoint"]
        ) {
          const d = this.t["轨迹"]["胖瘦"]["嘴角距"];
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.strokeStyle = this.kuankuanbiColor;
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.lineTo(
            d.endPoint.offsetX * dw + blackWidth,
            d.endPoint.offsetY * dh + blackHeight
          );
          this.context.closePath();
          this.context.stroke();
          this.context.restore();
          // 生成data里面的数据
          const data: any = JSON.parse(
            JSON.stringify(this.t["望舌质"]["胖瘦"])
          );
          data["is_labeled"] = true;
          data["嘴角距"] = this.calLineLength(
            this.t["轨迹"]["胖瘦"]["嘴角距"].startPoint,
            this.t["轨迹"]["胖瘦"]["嘴角距"].endPoint
          ).toFixed(2);
          if (data["嘴角距"] && data["舌边距"]) {
            data["宽宽比"] = (data["嘴角距"] / data["舌边距"]).toFixed(3);
          } else {
            data["宽宽比"] = 0;
          }
          this.t["望舌质"]["胖瘦"] = data;
        }
        if (
          this.t["轨迹"]["胖瘦"]["舌边距"] &&
          this.t["轨迹"]["胖瘦"]["舌边距"]["startPoint"] &&
          this.t["轨迹"]["胖瘦"]["舌边距"]["endPoint"]
        ) {
          const d = this.t["轨迹"]["胖瘦"]["舌边距"];
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.strokeStyle = this.kuankuanbiColor;
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.lineTo(
            d.endPoint.offsetX * dw + blackWidth,
            d.endPoint.offsetY * dh + blackHeight
          );
          this.context.closePath();
          this.context.stroke();
          this.context.restore();
          // 生成data里面的数据
          const data: any = JSON.parse(
            JSON.stringify(this.t["望舌质"]["胖瘦"])
          );
          data["is_labeled"] = true;
          data["舌边距"] = this.calLineLength(
            this.t["轨迹"]["胖瘦"]["舌边距"].startPoint,
            this.t["轨迹"]["胖瘦"]["舌边距"].endPoint
          ).toFixed(2);
          if (data["嘴角距"] && data["舌边距"]) {
            data["宽宽比"] = (data["嘴角距"] / data["舌边距"]).toFixed(3);
          } else {
            data["宽宽比"] = 0;
          }
          this.t["望舌质"]["胖瘦"] = data;
        }
      }
      // 舌态
      if (
        this.t["轨迹"]["舌态"] &&
        this.t["轨迹"]["舌态"]["歪斜角度"] &&
        this.t["轨迹"]["舌态"]["歪斜角度"]["endPoint"]
      ) {
        const d = this.t["轨迹"]["舌态"]["歪斜角度"];
        const dw = this.sourceImage.width / d.width;
        const dh = this.sourceImage.height / d.height;
        const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
        const blackHeight = (this.canvas.height - this.sourceImage.height) / 2;
        this.context.save();
        // 画基线
        this.drawJixian();
        // 画角度线
        this.context.beginPath();
        this.context.strokeStyle = this.shetaiColor;
        this.context.moveTo(this.canvas.width / 2, 0);
        this.context.lineTo(
          d.endPoint.offsetX * dw + blackWidth,
          d.endPoint.offsetY * dh + blackHeight
        );
        this.context.closePath();
        this.context.stroke();
        this.context.restore();
        // 计算2条直线的夹角
        const centerPoint = {
          offsetX: this.canvas.width / 2,
          offsetY: 0,
        };
        const jiaoPoint = {
          offsetX: d.endPoint.offsetX * dw + blackWidth,
          offsetY: d.endPoint.offsetY * dh + blackHeight,
        };
        const jiaodu = this.getJiaodu(jiaoPoint, centerPoint);
        const data: any = JSON.parse(JSON.stringify(this.t["望舌质"]["舌态"]));
        data["is_labeled"] = true;
        data["歪斜角度"] = jiaodu;
        this.t["望舌质"]["舌态"] = data;
      }
      // 苔质
      if (this.t["轨迹"]["苔质"] && Array.isArray(this.t["轨迹"]["苔质"])) {
        const arr: any[] = [];
        const orArr: any[] = [];
        let totalArea: any = 0;
        this.t["轨迹"]["苔质"].forEach((item: any) => {
          arr.push({
            area: item["area"].toFixed(2) + "px²",
          });
          orArr.push(Number(item["area"].toFixed(2)));
          totalArea += item["area"];
          const d = item;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.strokeStyle = this.taizhiColor;
          d.path.forEach((path: any) => {
            this.context.lineTo(
              path.offsetX * dw + blackWidth,
              path.offsetY * dh + blackHeight
            );
            this.context.stroke();
          });
          this.context.closePath();
          this.context.stroke();
          this.context.restore();
        });
        const data: any = JSON.parse(
          JSON.stringify(this.t["望舌苔"]["苔质面积占舌比"])
        );
        if (arr.length > 0) {
          data["is_labeled"] = true;
          data["苔质数据"] = arr;
          data["苔质面积"] = orArr[0].toFixed(2);
          data["苔质面积占舌比"] = (orArr[0] / this.cuttedArea).toFixed(4);
        } else {
          data["is_labeled"] = false;
          data["苔质面积"] = 0;
          data["苔质面积占舌比"] = 0;
        }

        this.t["望舌苔"]["苔质面积占舌比"] = data;
      }
      // 剥落
      if (this.t["轨迹"]["剥落"] && Array.isArray(this.t["轨迹"]["剥落"])) {
        const arr: any[] = [];
        const orArr: any[] = [];
        let totalArea: any = 0;
        this.t["轨迹"]["剥落"].forEach((item: any) => {
          arr.push({
            area: item["area"].toFixed(2) + "px²",
          });
          orArr.push(Number(item["area"].toFixed(2)));
          totalArea += item["area"];

          const d = item;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.strokeStyle = this.boluoColor;
          d.path.forEach((path: any) => {
            this.context.lineTo(
              path.offsetX * dw + blackWidth,
              path.offsetY * dh + blackHeight
            );
            this.context.stroke();
          });
          this.context.closePath();
          this.context.stroke();
          this.context.restore();
        });
        const data: any = JSON.parse(JSON.stringify(this.t["望舌苔"]["剥落"]));
        if (arr.length > 0) {
          data["is_labeled"] = true;
          data["剥落数据"] = arr;
          data["剥落面积占舌比"] = (totalArea / this.cuttedArea).toFixed(4);
        } else {
          data["is_labeled"] = false;
          data["剥落面积占舌比"] = 0;
        }
        this.t["望舌苔"]["剥落"] = data;
      }
    }
  }
  /**
   * @description 绘制黑色背景
   */
  private drawBlackBack() {
    this.context.save();
    return new Promise((resolve) => {
      this.context.fillRect(
        -this.canvas.width * 2,
        -this.canvas.height * 2,
        this.canvas.width * 5,
        this.canvas.height * 5
      );
      this.context.restore();
      resolve(true);
    });
  }
  private test(key: any) {
    alert(key);
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.calCuttedTongueArea();
    this.initCanvas();
    // 每次窗口大小改变时需要重新计算画布大小
    window.onresize = () => {
      this.initCanvas();
    };
  }
}
