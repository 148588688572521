



















































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private tongue: any;
  @Prop()
  private cBiaozhu: any = "";
  private youwu: any = ["有", "无"];
  private taise: any = ["白", "黄", "黄白相兼", "灰黑"];
  private houbao: any = ["极厚", "稍厚", "薄", "少", "无"];
  private boluo: any = [
    "前剥苔",
    "中剥苔",
    "根剥苔",
    "花剥苔",
    "鸡心苔",
    "镜面舌",
    "地图舌",
    "类剥苔",
  ];
  private pianquan: any = ["无偏全", "偏", "全"];
  private zhenjia: any = ["真苔", "假苔"];
  private runzao: any = ["润苔", "滑苔", "燥苔", "糙苔"];
  private ifShowSeduDetail: boolean = false;
  private cPart: any = "LAB";
  private get t() {
    return this.tongue;
  }
  private set t(val) {
    this.$emit("update:tongue", val);
  }
  private get c() {
    return this.cBiaozhu;
  }
  private set c(val) {
    this.$emit("update:cBiaozhu", val);
  }
  private get root() {
    return this.$refs.root as HTMLElement;
  }
  private getColor(type: any) {
    switch (type) {
      case "齿痕":
        return "red";
      case "裂纹":
        return "#67D5B5";
      case "点刺":
        return "yellow";
      case "瘀点":
        return "green";
      case "瘀斑":
        return "blue";
      case "胖瘦":
        return "black";
      case "舌态":
        return "#EA0AF6";
      case "苔质":
        return "#0AF60A";
      case "剥落":
        return "orange";
    }
  }
  /**
   * @description 打开色度值详情
   */
  private openSeduDetail() {
    this.ifShowSeduDetail = true;
  }
  /**
   * @description 改变部分
   */
  private changeSeduPart(part: any) {
    this.cPart = part;
  }
  /**
   * @description 删除某个苔质
   */
  private deleteTaizhi(index: any) {
    this.$emit("deleteTaizhi", index);
  }
  /**
   * @description 删除某个剥落
   */
  private deleteBoluo(index: any) {
    this.$emit("deleteBoluo", index);
  }
  /**
   * @description 删除舌苔局部放大图
   */
  private deleteJubushetai(index: any) {
    this.$emit("deleteJubushetai", index);
  }
  /**
   * @description 点击开始标注
   */
  private startBiaozhu(part: any) {
    this.c = part;
    if (part === "苔质") {
      this.$emit("biaozhuTaizhi");
    } else if (part === "剥落") {
      this.$emit("biaozhuBoluo");
    } else if (part === "舌苔局部放大图") {
      this.$emit("biaozhuJubushetai");
    }
  }
}
